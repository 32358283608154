import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import DateFnsUtils from '@date-io/moment';
import { getAffiliateGroupList, getExternalSoftwareList, getLeads, getLeadStatuses, getLeadUsers, getMarkets, getReferralTypes, getRevenueTiers } from "../../Actions";
import CreateLead from "./CreateLead";
import LeadListItem from "./LeadListItem";

export default function Leads(props) {
  const [refresh, setRefresh] = useState(true);
  const [leads, setLeads] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filter, setFilter] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [leadStatuses, setLeadStatuses] = useState([]);
  const [selectedLeadStatus, setSelectedLeadStatus] = useState("");
  const [markets, setMarkets] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [revenueTiers, setRevenueTiers] = useState([]);
  const [selectedRevenueTier, setSelectedRevenueTier] = useState("");
  const [referralTypes, setReferralTypes] = useState([]);
  const [selectedReferralType, setSelectedReferralType] = useState("");
  const [affiliateGroups, setAffiliateGroups] = useState([]);
  const [selectedAffiliateGroup, setSelectedAffiliateGroup] = useState("");
  const [externalSoftwares, setExternalSoftwares] = useState([]);
  const [selectedExternalSoftware, setSelectedExternalSoftware] = useState("");
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [isCreateLeadDialogOpen, setIsCreateLeadDialogOpen] = useState(false);

  useEffect(() => {
    async function getLeadData() {
      await getLeads()
        .then(result => {
          const leadObjs = result.data.leads.map(lead => {
            const company = lead.company_id ? result.data.companies.find(company => company.company_id === lead.company_id) : undefined;
            const site = result.data.sites.find(site => site.lead_id === lead.lead_id);
            const contacts = result.data.contacts.filter(contact => contact.lead_id === lead.lead_id) ?? [];
            const markets = lead.company_id ? result.data.company_markets.filter(market => market.company_id === lead.company_id) : [];

            return {
              ...lead,
              company,
              site,
              contacts,
              markets
            }
          });

          const dedupedCompanies = [...new Set(result.data.companies)];

          setCompanyList(dedupedCompanies);

          const states = [];

          dedupedCompanies.forEach(company => {
            if (states.find(s => s.state === company.state && s.country === company.country)) return;

            states.push({
              state: company.state,
              country: company.country
            });
          });

          setStateList([...states]);
          setLeads(leadObjs);
        }).catch(err => {
          setLeads([]);
        });

      await getLeadStatuses()
        .then(result => {
          setLeadStatuses(result.data.data);
        }).catch(err => {
          setLeadStatuses([]);
        });

      await getMarkets()
        .then(result => {
          setMarkets(result.data.data);
        }).catch(err => {
          setMarkets([]);
        });

      await getReferralTypes()
        .then(result => {
          setReferralTypes(result.data.data);
        }).catch(err => {
          setReferralTypes([]);
        });
      
      await getRevenueTiers()
        .then(result => {
          setRevenueTiers(result.data.data);
        }).catch(err => {
          setRevenueTiers([]);
        });

      await getAffiliateGroupList()
        .then(result => {
          setAffiliateGroups(result.data.data);
        }).catch(err => {
          setAffiliateGroups([]);
        });

      await getExternalSoftwareList()
        .then(result => {
          setExternalSoftwares(result.data.data);
        }).catch(err => {
          setExternalSoftwares([]);
        });

      await getLeadUsers()
        .then(result => {
          setUsers(result.data.data);
        }).catch(err => {
          setUsers([]);
        });
    }

    if (refresh) {
      getLeadData();

      const endDate = new Date();
      const startDate = new Date(endDate.getTime() - (1000 * 60 * 60 * 24 * 30));
      setSelectedEndDate(endDate.toDateString());
      setSelectedStartDate(startDate.toDateString());

      setRefresh(false);
    }
  }, [refresh])

  const isFuzzyMatch = (companyName) => {
    if (!filter) return true;

    const regex = new RegExp(`${filter}`, "gi");
    
    if (companyName && regex.test(companyName)) return true;
    
    return false;
  }

  const passesFilterChecks = (lead) => {

    if (new Date(lead.created_dttm.split('T')[0]) < new Date(selectedStartDate).getTime() || new Date(lead.created_dttm.split('T')[0]).getTime() > (new Date(selectedEndDate).getTime() + (1000 * 60 * 60 * 24) - 1)) {
      return false;
    }

    if (selectedLeadStatus !== "" && lead.lead_status_id !== selectedLeadStatus) {
      return false;
    }

    if (selectedMarket !== "" && !lead.markets.find(market => market.market_id === selectedMarket)) {
      return false;
    }

    if (selectedState !== "" && lead.company.state != selectedState) {
      return false;
    }

    if (selectedReferralType !== "" && lead.referral_type_id !== selectedReferralType) {
      return false;
    }

    if (selectedRevenueTier !== "" && lead.revenue_tier_id !== selectedRevenueTier) {
      return false;
    }

    if (selectedUser !== "" && ((selectedUser !== "-1" && lead.assigned_to !== selectedUser) || (selectedUser === "-1" && lead.assigned_to))) {
      return false;
    }

    if (selectedExternalSoftware !== "" && lead.external_software_id !== selectedExternalSoftware) {
      return false;
    }

    if (selectedAffiliateGroup !== "" && lead.affiliate_group_id !== selectedAffiliateGroup) {
      return false;
    }

    return true;
  } 

  const filteredList = leads.filter((lead) => isFuzzyMatch(lead.company?.company) && passesFilterChecks(lead));
  const paginatedList = filteredList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const isFirstPage = page === 0;
  const isLastPage = ((page + 1) * rowsPerPage) >= filteredList.length;
  const currentStart = Math.max(page * rowsPerPage, 1);
  const currentEnd = Math.min((page * rowsPerPage) + rowsPerPage, filteredList.length);
  const rowsPerPageOptions = [10, 25, 50, 100, 500];
  const leadItemList = paginatedList.map(lead => {
    return (
      <LeadListItem 
        key={lead.lead_id}
        lead={lead}
        statuses={leadStatuses}
        markets={markets}
        revenueTiers={revenueTiers}
        referralTypes={referralTypes}
        affiliateGroups={affiliateGroups}
        externalSoftwares={externalSoftwares}
        users={users}
        refreshData={() => setRefresh(true)}
      />
    )
  })

  return (
    <div>
      <Paper elevation={3}>
        <div className="siteinfo__header">
          <Typography variant="h4">
            Leads
          </Typography>
        </div>
        <div style={{textAlign: "left"}}>
          <Grid container spacing={2} style={{padding: "20px"}}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Typography>Company Search</Typography>
              <Autocomplete
                inputValue={filter}
                options={companyList}
                getOptionLabel={(option) => option?.company ?? ""}
                renderOption={(option) => (
                  <span key={option.company_id} id={option.company_id} style={{width: "100%"}}>
                    <Typography variant="body2"><b>{option.company}</b></Typography>
                    <Typography variant="body2">{option.city + ", " + option.state}</Typography>
                  </span>
                )}
                filterOptions={(options) => {
                  return options.filter((company) => isFuzzyMatch(company?.company))
                }}
                renderInput={(params) => <TextField {...params} label="Name" variant="filled" inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password'
                }}/>}
                fullWidth
                onInputChange={(e, value) => setFilter(value)}
                onChange={(e, data) => setFilter(data?.company ?? "")}
              />
            </Grid>
            <Grid item>
              <Typography>
                Time Range (UTC) 
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-DD"
                  value={selectedStartDate}
                  onChange={(e) => setSelectedStartDate(e)}
                  KeyboardButtonProps={{
                    'aria-label': 'change subscription end date',
                  }}
                  label="Start"
                  inputVariant="filled"
                  maxDate={selectedEndDate}
                  style={{marginRight: "20px", width: "200px"}}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-DD"
                  value={selectedEndDate}
                  minDate={selectedStartDate}
                  onChange={(e) => setSelectedEndDate(e)}
                  KeyboardButtonProps={{
                    'aria-label': 'change subscription end date',
                  }}
                  label="End"
                  inputVariant="filled"
                  style={{width: "200px"}}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Typography>Lead Status</Typography>
              <Select
                value={selectedLeadStatus}
                onChange={(e) => setSelectedLeadStatus(e.target.value)}
                variant="filled"
                fullWidth
                displayEmpty
              >
                <MenuItem value={""}>
                  All Statuses
                </MenuItem>
                { 
                  leadStatuses?.map(status => {
                    return (
                      <MenuItem key={status.lead_status_id} value={status.lead_status_id}>
                        {status.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Typography>Market</Typography>
              <Select
                value={selectedMarket}
                onChange={(e) => setSelectedMarket(e.target.value)}
                fullWidth
                variant="filled"
                displayEmpty
              >
                <MenuItem value={""}>
                  All Markets
                </MenuItem>
                { 
                  markets?.map(market => {
                    return (
                      <MenuItem key={market.market_id} value={market.market_id}>
                        {market.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Typography>State</Typography>
              <Select
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
                variant="filled"
                displayEmpty
                fullWidth
              >
                <MenuItem value={""}>
                  All States
                </MenuItem>
                {stateList.sort((a, b) => a.state.localeCompare(b.state)).map((location) => {
                  return (
                    <MenuItem key={location.state} value={location.state}>
                      <Typography variant="body2"><b>{location.state}</b> ({location.country})</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Typography>Revenue Tier</Typography>
              <Select
                value={selectedRevenueTier}
                onChange={(e) => setSelectedRevenueTier(e.target.value)}
                fullWidth
                variant="filled"
                displayEmpty
              >
                <MenuItem value={""}>
                  All Tiers
                </MenuItem>
                { 
                  revenueTiers?.map(tier => {
                    return (
                      <MenuItem key={tier.revenue_tier_id} value={tier.revenue_tier_id}>
                        {tier.description}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Typography>Referral Type</Typography>
              <Select
                value={selectedReferralType}
                onChange={(e) => setSelectedReferralType(e.target.value)}
                fullWidth
                variant="filled"
                displayEmpty
              >
                <MenuItem value={""}>
                  All Types
                </MenuItem>
                { 
                  referralTypes?.map(type => {
                    return (
                      <MenuItem key={type.referral_type_id} value={type.referral_type_id}>
                        {type.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Typography>Assigned To</Typography>
              <Select
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
                fullWidth
                variant="filled"
                displayEmpty
              >
                <MenuItem value={""}>
                  All Users
                </MenuItem>
                <MenuItem value={"-1"}>
                  Unassigned
                </MenuItem>
                { 
                  users?.map(user => {
                    return (
                      <MenuItem key={user.user_id} value={user.user_id}>
                        {user.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Typography>External Software</Typography>
              <Select
                value={selectedExternalSoftware}
                onChange={(e) => setSelectedExternalSoftware(e.target.value)}
                fullWidth
                variant="filled"
                displayEmpty
              >
                <MenuItem value={""}>
                  All External Softwares
                </MenuItem>
                { 
                  externalSoftwares?.map(software => {
                    return (
                      <MenuItem key={software.external_software_id} value={software.external_software_id}>
                        {software.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Typography>Affiliate Group</Typography>
              <Select
                value={selectedUser}
                onChange={(e) => setSelectedAffiliateGroup(e.target.value)}
                fullWidth
                variant="filled"
                displayEmpty
              >
                <MenuItem value={""}>
                  All Affiliate Groups
                </MenuItem>
                { 
                  affiliateGroups?.map(group => {
                    return (
                      <MenuItem key={group.affiliate_group_id} value={group.affiliate_group_id}>
                        {group.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={() => setIsCreateLeadDialogOpen(true)} style={{marginTop: "25px"}}>Create Lead</Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{padding: "20px", textAlign: "right", justifyContent: "end", alignItems: "center"}}>
            <Grid item>
              <FormControl style={{width: "150px", textAlign: "left"}}>
                <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
                <Select
                  labelId="rows-per-page-label"
                  id="rows-per-page"
                  value={rowsPerPage}
                  variant="filled"
                  fullWidth
                  onChange={(e) => setRowsPerPage(e.target.value)}
                >
                  {rowsPerPageOptions.map(o => {
                    return (
                      <MenuItem key={o} value={o}>{o}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Typography style={{width:"200px"}}>{currentStart} - {currentEnd} of {filteredList.length}</Typography>
            </Grid>
            <Grid item>
              <Button 
                variant="text" 
                disabled={isFirstPage} 
                style={{backgroundColor: "rgba(0, 0, 0, 0.09)", margin: "0px 0px 0px 0px"}} 
                onClick={() => setPage(isFirstPage ? 0 : page - 1)}
              >
                <ChevronLeft /> Prev
              </Button>
              <Button 
                variant="text" 
                disabled={isLastPage} 
                style={{backgroundColor: "rgba(0, 0, 0, 0.09)", margin: "0px 0px 0px 15px"}} 
                onClick={() => setPage(isLastPage ? page : page + 1)}
              >
                Next <ChevronRight /> 
              </Button>
            </Grid>
          </Grid>
          <div style={{textAlign: "left", padding: "0px", borderTop: "1px solid #838383"}}>
            { leadItemList.length ? leadItemList : (
                <Typography style={{padding: "20px", borderBottom: "1px solid #838383"}}>No lead records found</Typography>
              )
            }
          </div>
          <Grid container spacing={2} style={{padding: "20px", textAlign: "right", justifyContent: "end", alignItems: "center"}}>
            <Grid item>
              <FormControl style={{width: "150px", textAlign: "left"}}>
                <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
                <Select
                  labelId="rows-per-page-label"
                  id="rows-per-page"
                  value={rowsPerPage}
                  variant="filled"
                  fullWidth
                  onChange={(e) => setRowsPerPage(e.target.value)}
                >
                  {rowsPerPageOptions.map(o => {
                    return (
                      <MenuItem key={o} value={o}>{o}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Typography style={{width:"200px"}}>{currentStart} - {currentEnd} of {filteredList.length}</Typography>
            </Grid>
            <Grid item>
              <Button 
                variant="text" 
                disabled={isFirstPage} 
                style={{backgroundColor: "rgba(0, 0, 0, 0.09)", margin: "0px 0px 0px 0px"}} 
                onClick={() => setPage(isFirstPage ? 0 : page - 1)}
              >
                <ChevronLeft /> Prev
              </Button>
              <Button 
                variant="text" 
                disabled={isLastPage} 
                style={{backgroundColor: "rgba(0, 0, 0, 0.09)", margin: "0px 0px 0px 15px"}} 
                onClick={() => setPage(isLastPage ? page : page + 1)}
              >
                Next <ChevronRight /> 
              </Button>
            </Grid>
          </Grid>
        </div>
        <CreateLead 
          isOpen={isCreateLeadDialogOpen}
          onClose={() => setIsCreateLeadDialogOpen(false)}
          statuses={leadStatuses}
          markets={markets}
          revenueTiers={revenueTiers}
          referralTypes={referralTypes}
          affiliateGroups={affiliateGroups}
          externalSoftwares={externalSoftwares}
          users={users}
          refreshData={() => setRefresh(true)}
        />
      </Paper>
    </div>
  )
} 