import React, { useContext, useState, useEffect } from "react";

// styles
import "../styles/EditB2b.css";

// Actions
import { setB2bInfo, requestCatalog } from "../../Actions";

// MUI
import Checkbox from '@mui/material/Checkbox';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import { AlertContext } from "../../Context/AlertContextProvider";

// Actions
import { removeB2BConnection } from "../../Actions";
import { FormControlLabel, Grid, List, ListItem, ListItemText } from "@material-ui/core";



export default function B2BSettings (props) {
  const [reset, setReset] = useState(true);
  const [secondaryB2b, setSecondaryB2b] = useState(!!props.partner?.secondary_b2b ?? "");
  const [fcB2bVersion, setFcB2bVersion] = useState(props.partner?.alternate_fcb2b_version ?? "");
  const [altHost, setAltHost] = useState(props.partner?.alternate_host ?? "");
  const [altPartnerName, setAltPartnerName] = useState(props.partner?.alternate_partner_name ?? "");
  const [altPort, setAltPort] = useState(props.partner?.alternate_port ?? "");
  const [altTransferType, setAltTransferType] = useState(props.partner?.alternate_transfer_type ?? "");
  const [altLookbackDays, setAltLookbackDays] = useState(props.partner?.alternate_lookback_days ?? "");
  const [directory, setDirectory] = useState(props.partner?.host_directory ?? "");
  const [siteName, setSiteName] = useState(props.partner?.site_name ?? "")
  const [brand, setBrand] = useState(props.partner?.brand ?? "");
  const [interval, setInterval] = useState(props.partner?.interval_seconds ?? "");
  const [userName, setUserName] = useState(props.partner?.user ?? "");
  const [passWord, setPassWord] = useState(props.partner?.pass ?? "");
  const [ediId, setEdiId] = useState(props.partner?.edi_id);
  const [remarks, setRemarks] = useState(props.partner?.remarks ?? "");
  const [accountNumber, setAccountNumber] = useState(props.partner?.account_number ?? "");
  const [showDeletionDialog, setShowDeletionDialog] = useState(false);
  const [showCatalogRequestDialog, setShowCatalogRequestDialog] = useState(false);
  var myDate = new Date();
  var hours= myDate.getHours();
  let greeting = 'Hello'
  if (hours < 12)
  greeting =  "Good morning";
else if (hours >= 12 && hours <= 17)
  greeting = "Good afternoon";
else if (hours >= 17 && hours <= 24)
  greeting = "Goodevening";

  const emailBody = `${greeting}, time permitting, could you please upload a full product catalog to ${props.partner?.company}'s B2B server?  <br/> <br/> 
  Account: ${accountNumber}  <br/>
  Name: ${props.partner?.company} <br/>
  Phone: ${props.partner?.phone} <br/>  
  Email: ${props.partner?.email} <br/><br/>   
  
  Please let me know if there is anything else you need. Thanks!`;

  const emailSubject = `Full product catalog - ${props.partner?.company}`;

  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );

  useEffect(() => {
    if (!reset) return;

    setSecondaryB2b(!!props.partner.secondary_b2b);
    setFcB2bVersion(props.partner.alternate_fcb2b_version ?? "");
    setAltHost(props.partner.alternate_host ?? "");
    setAltPartnerName(props.partner.alternate_partner_name ?? "");
    setAltPort(props.partner.alternate_port ?? "");
    setAltTransferType(props.partner.alternate_transfer_type ?? "");
    setAltLookbackDays(props.partner.alternate_lookback_days ?? "");
    setDirectory(props.partner.host_directory ?? "");
    setSiteName(props.partner.site_name ?? "");
    setBrand(props.partner.brand ?? "");
    setInterval(props.partner.interval_seconds ?? "");
    setUserName(props.partner.user ?? "");
    setPassWord(props.partner.pass ?? "");
    setRemarks(props.partner.remarks ?? "");
    setAccountNumber(props.partner.account_number ?? "");
    setEdiId(props.partner.edi_id);
    setReset(false);

  }, [reset]);

const handleClearVars = () => {
    setPassWord(props.partner?.pass);
    props.refresh();
}

const handleChange = (e) => {
    setSecondaryB2b(e.target.checked);
};

const handleAltTransferTypeChange = (e) => {
    setAltTransferType(e.target.value);
};

const handleAltLookbackDaysChange = (e) => {
  setAltLookbackDays(e.target.value);
};

const handleRemarksChange = (e) => {
  setRemarks(e.target.value);
};

const handleAltPortChange = (e) => {
    setAltPort(e.target.value);
};

const handleAltPartnerNameChange = (e) => {
    setAltPartnerName(e.target.value);
};

const handleAltHostChange = (e) => {
    setAltHost(e.target.value);
};

const handleFcB2bChange = (e) => {
    setFcB2bVersion(e.target.value);    
};

const handleDirectoryChange = (e) => {
    setDirectory(e.target.value);
};

const handleBrandChange = (e) => {
    setBrand(e.target.value);
};

const handleIntervalChange = (e) => {
    setInterval(e.target.value);
};

const handleUserChange = (e) => {
    setUserName(e.target.value);
};

const handlePassChange = (e) => {
    setPassWord(e.target.value);
};

const handleAccountNumberChange = (e) => {
  setAccountNumber(e.target.value);
};

const handleRemoveB2BConnection = async (ediId, partnerId, siteId) => {
  removeB2BConnection(ediId, partnerId, siteId)
    .then((result) => {
      setMessage("B2B connection deleted successfully");
      setMessageType("success");
      handleAlertOpen();
      handleClose();
    })
    .catch((err) => {
      setMessage("Error deleting B2B connection")
      setMessageType("error");
      handleAlertOpen();
      console.log(err);
    });
};

const handleRequestCatalog = async () => {
  if (!accountNumber) {
    setMessage("Please enter an account number")
    setMessageType("error");
    handleAlertOpen();
  } else {
    requestCatalog(props.partner?.contact_email, emailSubject, emailBody)
    .then(() => {
      setMessage("Request sent!");
      setMessageType("success");
      handleAlertOpen();
    })
    .catch((err) => {
      setMessage("Error sending request");
      setMessageType("error");
      handleAlertOpen();
    });
} 
};

  const handleClose = () => {
    setShowDeletionDialog(false);
    props.refresh();
  };

  const handleSubmit = async () => {
    setB2bInfo(
      brand,
      siteName,
      remarks,
      altHost, 
      altPort, 
      altTransferType, 
      altLookbackDays,
      altPartnerName, 
      fcB2bVersion, 
      userName, 
      passWord, 
      directory,
      interval,
      secondaryB2b,
      ediId,
      accountNumber)
    .then((result) => {
      setMessage("Updated B2B connection info");
      setMessageType("success");
      handleAlertOpen();
      handleClearVars();
    })
    .catch((err) => {
      setMessage("Failed to update B2B connection info");
      setMessageType("error");
      handleAlertOpen();
    });
    handleClose();
  };

  return (
    <div style={{position: "relative"}}>
      <Typography variant="h4" style={{margin: "auto 20px 10px"}}>
        B2B Account Information
      </Typography>
      <div style={{maxHeight: "50vh", overflowY: "scroll", padding: "15px 20px 30px", borderTop: "1px solid #838383"}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6"><b>General</b></Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Partner Name *</Typography>
            <TextField
              fullWidth
              required={true}
              variant="filled"
              value={altPartnerName}
              onChange={handleAltPartnerNameChange}
            >
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Brand</Typography>
            <TextField
              fullWidth
              variant="filled"
              value={brand}
              onChange={handleBrandChange}
            >
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Account Number *</Typography>
            <TextField
              fullWidth
              required={true}
              variant="filled"
              value={accountNumber}
              onChange={handleAccountNumberChange}
            >
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Remarks</Typography>
            <TextField
              fullWidth
              variant="filled"
              multiline
              value={remarks}
              onChange={handleRemarksChange}
            >
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" style={{marginTop: "10px"}}><b>Credentials</b></Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>User Name *</Typography>
            <TextField
              fullWidth
              required={true}
              variant="filled"
              value={userName}
              onChange={handleUserChange}
            >
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Password *</Typography>
            <TextField
              fullWidth
              required={true}
              variant="filled"
              value={passWord}
              onChange={handlePassChange}
            >
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" style={{marginTop: "10px"}}><b>Connection</b></Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Directory</Typography>
            <TextField
              fullWidth
              variant="filled"
              value={directory}
              onChange={handleDirectoryChange}
            >
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Host *</Typography>
            <TextField
              fullWidth
              required={true}
              variant="filled"
              value={altHost}
              onChange={handleAltHostChange}
            >
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography>Port *</Typography>
            <TextField
              fullWidth
              required={true}
              variant="filled"
              value={altPort}
              onChange={handleAltPortChange}
            >
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography>Protocol *</Typography>
            <TextField
              fullWidth
              required={true}
              variant="filled"
              value={altTransferType}
              onChange={handleAltTransferTypeChange}
            >
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography>FCB2B Version *</Typography>
            <TextField
              fullWidth
              required={true}
              variant="filled"
              value={fcB2bVersion}
              onChange={handleFcB2bChange}
            >
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Lookback Days</Typography>
            <TextField
              fullWidth
              variant="filled"
              value={altLookbackDays}
              onChange={handleAltLookbackDaysChange}
            >
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Interval (s) *</Typography>
            <TextField
              fullWidth
              required={true}
              variant="filled"
              value={interval}
              onChange={handleIntervalChange}
            >
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>B2B Provider Type</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={secondaryB2b}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Is Secondary Provider?"
            />
          </Grid>
        </Grid>
      </div>
      
      <div style={{padding: "15px 10px", textAlign: "right", backgroundColor: "#FFFFFF", borderTop: "1px solid #838383"}}>
        <Button
          onClick={() => setReset(true)}
          variant="contained"
          style={{margin: "10px"}}
        >
          Cancel
        </Button>
        <Button
          onClick={() => setShowCatalogRequestDialog(true)}
          variant="contained"
          style={{margin: "10px", backgroundColor: "#142e3e", color: "#28c4fc"}}
        >
          Request Catalog
        </Button>
        <Button
          onClick={() => setShowDeletionDialog(true)}
          variant="contained"
          style={{margin: "10px", backgroundColor: "#f44336", color: "#FFFFFF"}}
        >
          Delete
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          style={{margin: "10px", backgroundColor: "#4caf50", color: "#FFFFFF"}}
        >
          Update
        </Button>
      </div>

      <Dialog open={showDeletionDialog} onClose={() => setShowDeletionDialog(false)}>
        <Paper style={{padding: "25px 20px 15px"}}>
          { !!props.partner?.active_ind ? (
              <>
                <Typography variant="h6" style={{marginBottom: "15px"}}>Oops. Looks like the <b>{props.partner?.name}{props.partner?.brand ? " (" + props.partner?.brand + ")" : ""}</b> connection is still running. Please stop it first and try again.</Typography>
                <div style={{textAlign: "right"}}>
                  <Button
                    onClick={() => setShowDeletionDialog(false)}
                    variant="contained"
                    style={{margin: "10px 0px 10px 20px"}}
                  >
                    Okay
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Typography variant="h6" style={{marginBottom: "15px"}}>Are you sure you want to delete the <b>{props.partner?.name}{props.partner?.brand ? " (" + props.partner?.brand + ")" : ""}</b> connection?</Typography>
                <div style={{textAlign: "right"}}>
                  <Button
                    onClick={() => setShowDeletionDialog(false)}
                    variant="contained"
                    style={{margin: "10px 0px 10px 20px"}}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleRemoveB2BConnection(props.partner?.edi_id, props.partner?.partner_id, props.partner?.site_id)}
                    variant="contained"
                    style={{margin: "10px 0px 10px 20px", backgroundColor: "#f44336", color: "#FFFFFF"}}
                  >
                    Delete Connection
                  </Button>
                </div>
              </>
            )
          }
        </Paper>
      </Dialog>
      <Dialog open={showCatalogRequestDialog} onClose={() => setShowCatalogRequestDialog(false)}>
        <Paper style={{padding: "25px 20px 15px"}}>
          { props.partner?.alternate_host && props.partner?.alternate_port && props.partner?.pass && props.partner?.user && props.partner?.active_ind && props.partner?.account_number? (
              <>
                <Typography variant="h6" style={{marginBottom: "15px"}}>
                  Are you sure you want to request a full catalog for <b>{props.partner?.name}{props.partner?.brand ? " (" + props.partner?.brand + ")" : ""}</b>?
                </Typography>
                <Typography variant="h6" style={{marginBottom: "15px"}}>Please note that only one request should be sent in a day.</Typography>
                <div style={{textAlign: "right"}}>
                  <Button
                    onClick={() => setShowCatalogRequestDialog(false)}
                    variant="contained"
                    style={{margin: "10px 0px 10px 20px"}}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleRequestCatalog()}
                    variant="contained"
                    style={{margin: "10px 0px 10px 20px", backgroundColor: "#142e3e", color: "#28c4fc"}}
                  >
                    Send Catalog Request
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Typography variant="h6" style={{marginBottom: "10px"}}>
                  Unable to send request for <b>{props.partner?.name}{props.partner?.brand ? " (" + props.partner?.brand + ")" : ""}</b>
                </Typography>
                <Typography variant="h6">Please check that the connection is started and the following fields are filled in</Typography>
                <List>
                  <ListItem style={{padding: "0px 15px"}}>
                    <ListItemText>- Account Number</ListItemText>
                  </ListItem>
                  <ListItem style={{padding: "0px 15px"}}>
                    <ListItemText>- Username</ListItemText>
                  </ListItem>
                  <ListItem style={{padding: "0px 15px"}}>
                    <ListItemText>- Password</ListItemText>
                  </ListItem>
                  <ListItem style={{padding: "0px 15px"}}>
                    <ListItemText>- Host</ListItemText>
                  </ListItem>
                  <ListItem style={{padding: "0px 15px"}}>
                    <ListItemText>- Port</ListItemText>
                  </ListItem>
                </List>
                <div style={{textAlign: "right"}}>
                  <Button
                    onClick={() => setShowCatalogRequestDialog(false)}
                    variant="contained"
                    style={{margin: "10px 0px 10px 20px"}}
                  >
                    Close
                  </Button>
                </div>
              </>
            )}
        </Paper>
      </Dialog>
    </div>
  )
};