import axios from "axios";

// constants
import { baseUrl } from "../Contants";

export const logout = () => {
  localStorage.clear();
  return axios.get(`${baseUrl}/api/auth/logout`);
};

// products actions

export const getProductsWithFilters = url => {
  return axios.get(`${baseUrl}/api/user/products${url}`);
};

export const getProductDetails = (productId, roleId) => {
  return axios.get(`${baseUrl}/api/user/product/${productId}/${roleId}`);
};

// product actions
export const updateProduct = (
  costId,
  margin,
  effectiveStartDate,
  effectiveEndDate,
  comments
) => {
  const data = { margin, effectiveStartDate, effectiveEndDate, comments };
  const url = `${baseUrl}/api/user/costs/${costId}/margin`;
  return axios.put(url, data);
};

export const resetMargin = marginId => {
  const url = `${baseUrl}/api/user/margins/${marginId}`;
  return axios.delete(url);
};

// user actions
export const createUser = (userName, accessTypeCode) => {
  const url = `${baseUrl}/api/admin/users`;
  return axios.post(url, { userName, accessTypeCode });
};

export const registerLocalUser = (
  name,
  username,
  password,
  accesstype

) => {
  const url = `${baseUrl}/api/signup`;
  return axios.post(url, {
    name,
    username,
    password,
    accesstype
  });
};

export const updateUser = (name, email, userId, roleId, accessTypeCode) => {
  const url = `${baseUrl}/api/admin/users/${userId}`;
  return axios.put(url, { name, email, roleId, accessTypeCode });
};

export const deleteUser = userId => {
  const url = `${baseUrl}/api/admin/users/${userId}`;
  return axios.delete(url);
};

export const createRole = (role_name, role_description) => {
  const url = `${baseUrl}/api/admin/roles/`;
  return axios.post(url, { role_name, role_description });
};

export const getRoleToMargin = role_id => {
  const url = `${baseUrl}/api/admin/roles/${role_id}/margins`;
  return axios.get(url, { role_id });
};

export const upsertRoleToMargin = (
  role_id,
  material_class_cd,
  margin,
  add_to_cost
) => {
  const url = `${baseUrl}/api/admin/roles/${role_id}/margins/`;
  return axios.post(url, { role_id, material_class_cd, margin, add_to_cost });
};

export const getRoles = () => {
  return axios.get(`${baseUrl}/api/admin/roles`);
};

export const updateRole = (role_id, role_name, role_description) => {
  const url = `${baseUrl}/api/admin/roles/${role_id}`;
  return axios.post(url, { role_name, role_description });
};

export const updateUserRole = role_id => {
  const url = `${baseUrl}/api/admin/updateuserrole/${role_id}`;
  return axios.post(url, { role_id });
};

export const editRolePrefix = (role_id, switch_state) => {
  const url = `${baseUrl}/api/admin/roleprefix`;
  return axios.post(url, { role_id, switch_state });
};

export const deleteRole = role_id => {
  const url = `${baseUrl}/api/admin/roles/${role_id}`;
  return axios.delete(url, { role_id });
};

// announcement actions
export const createAnnouncement = (
  title,
  announcement,
  effectStartDate,
  effectEndDate
) => {
  const url = `${baseUrl}/api/admin/announcements`;
  return axios.post(url, {
    title,
    announcement,
    effectStartDate,
    effectEndDate
  });
};

export const updateAnnouncement = (
  announcementId,
  title,
  announcement,
  effectStartDate,
  effectEndDate
) => {
  const url = `${baseUrl}/api/admin/announcements/${announcementId}`;
  return axios.put(url, {
    title,
    announcement,
    effectStartDate,
    effectEndDate
  });
};

export const deleteAnnouncement = announcementId => {
  const url = `${baseUrl}/api/admin/announcements/${announcementId}`;
  return axios.delete(url);
};

// bulletin actions

export const createBulletin = data => {
  const url = `${baseUrl}/api/admin/bulletin`;
  return axios.post(url, data);
};

export const deleteBulletin = id => {
  const url = `${baseUrl}/api/admin/bulletin/${id}`;
  return axios.delete(url);
};

export const updateBulletin = (bulletinId, data) => {
  const url = `${baseUrl}/api/admin/bulletins/${bulletinId}`;
  return axios.put(url, data);
};

// print actions
export const updatePrintQueue = productUUIDList => {
  const url = `${baseUrl}/api/user/queue`;
  return axios.post(url, { productUUIDList });
};

export const deleteFromPrintQueue = (printQueueId) => {
  const url = `${baseUrl}/api/user/products/${printQueueId}/queue`;
  return axios.delete(url);
};

export const clearPrintQueue = () => {
  const url = `${baseUrl}/api/user/queue`;
  return axios.delete(url);
};

export const printPreview = () => {
  return axios.get(`${baseUrl}/api/user/queue/print`);
};

// tax update actions
// 'http://localhost:3001'

export const updateTaxRate = taxRate => {
  const url = `${baseUrl}/api/admin/taxrate/update`;
  return axios.post(url, { taxRate });
};

export const updateCompanyMargin = companyMargin => {
  const url = `${baseUrl}/api/admin/margin/company`;
  return axios.post(url, { marginCompanyRate: Number(companyMargin) });
};

export const updateLoadedMargin = loadedMargin => {
  const url = `${baseUrl}/api/admin/margin/loaded`;
  return axios.post(url, { marginLoadedRate: Number(loadedMargin) });
};

export const updateMaterialClassMargin = (
  materialClass,
  materialClassMargin
) => {
  const url = `${baseUrl}/api/admin/margin/material`;
  return axios.post(url, {
    marginMaterialRates: [
      {
        material_class_cd: materialClass,
        material_margin: Number(materialClassMargin)
      }
    ]
  });
};

// login actions

export const login = (username, password) => {
  return axios.post(`${baseUrl}/api/login`, { username, password });
};

export const changePassword = (newPassword, confirmPassword, userId) => {
  return axios.post(`${baseUrl}/api/auth/local/user/password-change`, {
    newPassword,
    confirmPassword,
    userId
  });
};

export const uploadLogo = data => {
  const url = `${baseUrl}/api/admin/company/logo`;
  return axios.post(url, data);
};

// manual file uploads actions
export const getDistributorList = () => {
  return axios.get(`${baseUrl}/api/upload/distributor`);
};

export const getPreview = data => {
  const url = `${baseUrl}/api/upload/file`;
  return axios.post(url, data);
};

export const confirmManualUpload = data => {
  const url = `${baseUrl}/api/upload/file/confirm`;
  return axios.post(url, data);
};

export const getTemplateHeader = () => {
  return axios.get(`${baseUrl}/api/upload/templateheader`);
};

// File export actions
export const getDataExportList = () => {
  return axios.get(`${baseUrl}/api/exports/`);
};

export const getDataExportPreview = (export_id, action, role, manufacturers, materials, styles) => {
  let req = {
    role: JSON.parse(role),
    manufacturers: JSON.parse(manufacturers),
    materials: JSON.parse(materials),
    styles: JSON.parse(styles)
  }
  const url = `${baseUrl}/api/exports/${export_id}/${action}`;
  return axios.post(url, req);
};

// export const getDataExportPreview = (export_id, action, role, manufacturer, material) => {
//   console.log(material)
// return axios.post(`${baseUrl}/api/exports/${export_id}/${action}?role=${role}&manufacturer=${manufacturer}&material=${material}`);
// };

export const getManufacturers = () => {
  return axios.get(`${baseUrl}/api/ref/manufacturers`);
};

export const getDistributors = () => {
  return axios.get(`${baseUrl}/api/ref/distributors`);
};

export const getMaterials = () => {
  return axios.get(`${baseUrl}/api/ref/materials`);
};

export const getStyles = () => {
  return axios.get(`${baseUrl}/api/ref/styles`);
};

export const getTemplate = () => {
  const url = `${baseUrl}/api/upload/gettemplate`;
  return axios.post(url);
};

// disclaimer actions
export const getTaxDisclaimer = () => {
  return axios.get(`${baseUrl}/api/admin/taxdisclaimer`);
};

export const setTaxDisclaimerPosition = switch_position => {
  const url = `${baseUrl}/api/admin/taxdisclaimerposition`;
  return axios.post(url, { switch_position });
};

export const updateTaxDisclaimer = tax_disclaimer => {
  const url = `${baseUrl}/api/admin/disclaimer/tax`;
  return axios.post(url, { tax_disclaimer });
};

export const getProductDisclaimer = () => {
  return axios.get(`${baseUrl}/api/admin/disclaimer/product`);
};

export const updateProductDisclaimer = product_disclaimer => {
  const url = `${baseUrl}/api/admin/disclaimer/product`;
  return axios.post(url, { product_disclaimer });
};

// Product status (active, discontinued)
export const updateProductStatus = (cost_id, product_uuid, position) => {
  const url = `${baseUrl}/api/admin/product/status/set`;
  return axios.post(url, { cost_id, product_uuid, position });
};

// Logo Link
export const setLogoLink = company_link => {
  const url = `${baseUrl}/api/admin/logolink/set`;
  return axios.post(url, { company_link });
};

export const getLogoLink = () => {
  return axios.get(`${baseUrl}/api/admin/logolink/get`);
};

// Product Info

export const getProductLookBack = days => {
  return axios.get(`${baseUrl}/api/user/lookback/${days}`);
};

// File history

export const getLatestB2B = () => {
  return axios.get(`${baseUrl}/api/files/b2b/latest`)
};

export const getFilesByPartner = (partner) => {
  return axios.get(`${baseUrl}/api/files/b2b/partners/${partner}`);
};

export const getProductsByFilename = (filename) => {
  return axios.get(`${baseUrl}/api/files/b2b/${filename}/products`)
}
// Stats

export const getViewsByDay = (sDate, eDate) => {
  return axios.get(`${baseUrl}/api/stats/products/Viewsbyday/${sDate}/${eDate}`);
};

// Versions
export const getVersion = () => {
  return axios.get(`${baseUrl}/api/version`);
};

// Mailer
export const setMailer = (address, subject, body, siteName, partner_id, site_id) => {
  const url = `${baseUrl}/api/mail/mailer`;
  return axios.post(url, { address, subject, body, siteName, partner_id, site_id});
};

export const requestCatalog = (address, subject, body) => {
  const url = `${baseUrl}/api/mail/catalog`;
  return axios.post(url, { address, subject, body });
};

// Video 
export const getVideoLinks = () => {
  return axios.get(`${baseUrl}/api/user/video/links`);
};

export const addNewVideo = (videoUrl, newTitle, newDescription) => {
  const url = `${baseUrl}/api/admin/videos/newvideo`;
  return axios.post(url, { videoUrl, newTitle, newDescription });
};

export const deleteVideo = (video_id) => {
  const url = `${baseUrl}/api/admin/videos/deletevideo`;
  return axios.post(url, { video_id });
};

// B2B
export const addB2BConnection = (partner_id, site_id) => {
  const url = `${baseUrl}/api/site/b2b/add`;
  return axios.post(url, { partner_id, site_id });
};

export const removeB2BConnection = (edi_id, partner_id, site_id) => {
  const url = `${baseUrl}/api/site/b2b/delete`;
  return axios.post(url, { edi_id, partner_id, site_id });
};

export const testB2BConnection = (edi_id) => {
  return axios.get(`${baseUrl}/api/site/b2b/test/${edi_id}`);
}

export const getB2BServerPreview = (edi_id) => {
  return axios.get(`${baseUrl}/api/site/b2b/view/${edi_id}`);
}

export const getB2bEmailRequests = (site_url) => {
  const url = `${baseUrl}/api/admin/b2b/emailreq`;
  return axios.post(url, {site_url})
}

export const getB2bInfo = (site_id) => {
  const url = `${baseUrl}/api/site/b2binfo`;
  return axios.post(url, {site_id})
}

export const setB2bStatus = (active_ind, site_id, edi_id) => {
  const url = `${baseUrl}/api/site/b2binfo/setstatus`;
  return axios.post(url, { active_ind, site_id, edi_id });
};

export const setB2bInfo = (
  brand, 
  site_name,
  remarks,
  alternate_host, 
  alternate_port, 
  alternate_transfer_type, 
  alternate_lookback_days,
  alternate_partner_name, 
  alternate_fcB2b_version,
  user,
  pass,
  host_directory,
  interval,
  secondary_b2b,
  edi_id,
  account_number) => {
  const url = `${baseUrl}/api/site/b2binfo/setinfo`;
  return axios.post(url, {
  brand, 
  site_name,
  remarks,
  alternate_host, 
  alternate_port, 
  alternate_transfer_type, 
  alternate_lookback_days,
  alternate_partner_name, 
  alternate_fcB2b_version,
  user,
  pass,
  host_directory,
  interval,
  secondary_b2b,
  edi_id,
  account_number
  });
};

// CREATE NEW SITE

// new schema
export const createNewSite = (schemaName, type, externalSoftwareId, affiliateGroupId, leadId, companyId) => {
  const siteType = type != 0 ? null : 0;
  const url = `${baseUrl}/api/newsite/create`;
  return axios.post(url, {schemaName, siteType, externalSoftwareId, affiliateGroupId, leadId, companyId});
};

// create s3 bucket
export const createS3 = (s3Name) => {
  const url = `${baseUrl}/api/newsite/creates3`;
  return axios.post(url, {s3Name});
};

// create Ec2 instance
export const createEc2 = (ec2Name) => {
  const url = `${baseUrl}/api/newsite/createec2`;
  return axios.post(url, {ec2Name});
};

// describe ec2 instances
export const describeEc2Instances = () => {
  return axios.get(`${baseUrl}/api/newsite/describeec2instances`);
};

// start/stop instances
export const startStop = (instanceId, startStop) => {
  const url = `${baseUrl}/api/newsite/startstop`;
  return axios.post(url, {instanceId, startStop});
};

// DB instances
export const describeDbInstances = (instanceId) => {
  const url = (`${baseUrl}/api/newsite/describedbinstances`);
  return axios.post(url, {instanceId});
};

export const createDbInstance = (id) => {
  const url = `${baseUrl}/api/newsite/createDbInstance`;
  return axios.post(url, {id});
};

// Site status
export const getSiteStatusList = () => {
  return axios.get(`${baseUrl}/api/site/status`);
};

export const getSiteEC2InfoList = () => {
  return axios.get(`${baseUrl}/api/site/ec2info`);
};

export const getSiteInfoList = () => {
  return axios.get(`${baseUrl}/api/site/info`);
};

export const getSiteEC2InfoBySiteName = (site) => {
  return axios.get(`${baseUrl}/api/site/${site}/ec2info`);
};

export const getSiteInfoBySiteName = (site) => {
  return axios.get(`${baseUrl}/api/site/${site}/info`);
};

export const getExternalSoftwareList = () => {
  return axios.get(`${baseUrl}/api/site/externalsoftwares`);
}

export const addExternalSoftwareOption = (name) => {
  return axios.post(`${baseUrl}/api/site/create/externalsoftware`, {name});
}

export const setExternalSoftwareForSite = (site_id, external_software_id) => {
  return axios.post(`${baseUrl}/api/site/edit/externalsoftware`, {site_id, external_software_id});
}

export const getAffiliateGroupList = () => {
  return axios.get(`${baseUrl}/api/site/affiliategroups`);
}

export const addAffiliateGroupOption = (name) => {
  return axios.post(`${baseUrl}/api/site/create/affiliategroup`, {name});
}

export const setAffiliateGroupForSite = (site_id, affiliate_group_id) => {
  return axios.post(`${baseUrl}/api/site/edit/affiliategroup`, {site_id, affiliate_group_id});
}

export const setSubscriptionForSite = (site_id, subscription_status, subscription_end_dttm) => {
  return axios.post(`${baseUrl}/api/site/edit/subscription`, {site_id, subscription_status, subscription_end_dttm});
}

export const updateApplication = () => {
  return axios.get(`${baseUrl}/api/app/update`);
}

// Get S3
export const listS3Files = (site) => {
  return axios.get(`${baseUrl}/api/files/manual/${site}`);
};

// export const getS3Files = (site, file) => {
//   return axios.get(`${baseUrl}/api/files/manual/download/${site}/${file}`);
// };

export const getS3Files = (site, file) => {
  const url = `${baseUrl}/api/files/manual/download`;
  return axios.post(url, {site, file});
};

// Config
export const getConfig = (schema) => {
  const url = `${baseUrl}/api/admin/config`;
  return axios.post(url, {schema});
};

//Site Info
export const setContactInfo = (name, company_name, phone, email, street, city, state, zip, company_id, site_id) => {
  const url = `${baseUrl}/api/site/edit/contactinfo`;
  return axios.post(url, {name, company_name, phone, email, street, city, state, zip, company_id, site_id});
};

export const updateSiteContact = (contactId, name, email, phone) => {
  return axios.post(
    `${baseUrl}/api/site/update/contact/${contactId}`, 
    { name, email, phone }
  );
}

export const updateSelectedSiteContact = (siteId, contactId) => {
  return axios.post(
    `${baseUrl}/api/site/${siteId}/update/selectedcontact`, 
    { contactId }
  );
}

// B2B Tracking
export const getB2bLogs = () => {
  return axios.get(`${baseUrl}/api/b2b/logs`);
}

export const getB2bLogsByEdiId = (edi_id) => {
  return axios.get(`${baseUrl}/api/b2b/edi/${edi_id}/logs`);
}

// Leads
export const getLeads = () => {
  return axios.get(`${baseUrl}/api/leads`);
}

export const getLeadById = (leadId) => {
  return axios.get(`${baseUrl}/api/lead/${leadId}`);
}

export const getLeadStatuses = () => {
  return axios.get(`${baseUrl}/api/leads/statuses`);
}

export const getMarkets = () => {
  return axios.get(`${baseUrl}/api/leads/markets`);
}

export const addMarket = (name) => {
  return axios.post(`${baseUrl}/api/market/create`, { name });
}

export const getReferralTypes = () => {
  return axios.get(`${baseUrl}/api/leads/referraltypes`);
}

export const getRevenueTiers = () => {
  return axios.get(`${baseUrl}/api/leads/tiers`);
}

export const getLeadUsers = () => {
  return axios.get(`${baseUrl}/api/leads/users`);
}

export const addCompany = (name, url, street, city, state, zipCode, country) => {
  return axios.post(
    `${baseUrl}/api/company/create`, 
    { 
      name, 
      url, 
      street, 
      city, 
      state, 
      zipCode, 
      country 
    }
  );
}

export const addLead = (statusId, companyId, externalSoftwareId, affiliateGroupId, referralTypeId, revenueTierId, locationCount, assignedToId) => {
  return axios.post(
    `${baseUrl}/api/lead/create`, 
    { 
      statusId, 
      companyId, 
      externalSoftwareId, 
      affiliateGroupId, 
      referralTypeId, 
      revenueTierId, 
      locationCount, 
      assignedToId 
    }
  );
}

export const addContact = (name, email, phone, leadId) => {
  return axios.post(
    `${baseUrl}/api/lead/${leadId}/create/contact`, 
    { name, email, phone }
  );
}

export const addMarketsToCompany = (companyId, markets) => {
  return axios.post(
    `${baseUrl}/api/company/addmarkets`, 
    { companyId, markets }
  );
}

export const addEventToLead = (leadId, description) => {
  try {
    axios.post(
      `${baseUrl}/api/lead/${leadId}/create/event`, 
      { description }
    );   
  } catch (err) {
    return;
  } 
  
  return 
}

export const addNoteToLead = (leadId, note) => {
  return axios.post(
    `${baseUrl}/api/lead/${leadId}/create/note`, 
    { note }
  );
}

export const updateNoteById = (leadId, noteId, note) => {
  return axios.post(
    `${baseUrl}/api/lead/${leadId}/update/note/${noteId}`, 
    { note }
  );
}

export const deleteNoteById = (leadId, noteId) => {
  return axios.post(
    `${baseUrl}/api/lead/${leadId}/delete/note/${noteId}`
  );
}

export const updateLeadAssignment = (leadId, assignedTo) => {
  return axios.post(
    `${baseUrl}/api/lead/${leadId}/update/assign`, 
    { assignedTo }
  );
}

export const updateLeadStatus = (leadId, leadStatusId) => {
  return axios.post(
    `${baseUrl}/api/lead/${leadId}/update/status`, 
    { leadStatusId }
  );
}

export const updateLeadMarkets = (leadId, companyId, markets) => {
  return axios.post(
    `${baseUrl}/api/lead/${leadId}/update/markets`, 
    { companyId, markets }
  );
}

export const updateLeadAttributes = (leadId, revenueTierId, referralTypeId, affiliateGroupId, externalSoftwareId, locationCount) => {
  return axios.post(
    `${baseUrl}/api/lead/${leadId}/update/attributes`, 
    { revenueTierId, referralTypeId, affiliateGroupId, externalSoftwareId, locationCount }
  );
}

export const updateContact = (leadId, contactId, name, email, phone) => {
  return axios.post(
    `${baseUrl}/api/lead/${leadId}/update/contact/${contactId}`, 
    { name, email, phone }
  );
}

export const deleteContact = (leadId, contactId) => {
  return axios.post(
    `${baseUrl}/api/lead/${leadId}/delete/contact/${contactId}`
  );
}

export const getCompanies = () => {
  return axios.get(`${baseUrl}/api/leads/companies`);
}

export const updateCompany = (companyId, name, websiteUrl, address, city, state, zipCode, country) => {
  return axios.post(
    `${baseUrl}/api/company/${companyId}/update`, 
    { 
      name, 
      websiteUrl, 
      address, 
      city, 
      state, 
      zipCode, 
      country 
    }
  );
}

export const getContacts = (leadId) => {
  return axios.get(`${baseUrl}/api/lead/${leadId}/contacts`);
}